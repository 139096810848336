export const apiRoutes = {
    flightTrips: {
        getFlightTrips: '/flight-trips',
    },
    rules: {
        getAllRules: '/rules/getAllRules',
    },
    dcmm:{
        findBySerialNumber :'devices/findBySerialNumber',
        updateLocation: 'devices/updateLocation'
    },
    templates:{
        getById:"templates/getById"
    }
};
