import React, { createContext, useContext, useRef } from 'react';
import { Socket } from 'socket.io-client';

interface SocketContextProps {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextProps | null>(null);

export const SocketProvider: React.FC<{ socket: Socket | null, children: React.ReactNode }> = ({ socket, children }) => {
  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
