import React, { useEffect, useRef, useState } from 'react';
import TemplateComponent from '../components/TemplateComponent';
import moment from 'moment';
import axiosInstanceTemplate from '../utils/axiosConfigTemplate';
import { Template } from '../interface/template';
import { useParams } from 'react-router-dom';
import NotFoundTemplate from '../components/NotFoundTemplate';
import { createSocket } from '../socket/create.socket';
import { Socket } from 'socket.io-client';
import { SocketProvider } from '../context/SocketContext';

const TemplatePage: React.FC = () => {
  const [updatedAt, setUpdatedAt] = useState<Date>();
  const [currentTime, setCurrentTime] = useState(moment());
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default value, dynamically updated
  const [previousDate, setPreviousDate] = useState(moment().format('YYYY-MM-DD')); // Track previous date
  const [isDateChanges, setIsDateChanges] = useState(false);
  const { templateId } = useParams<{ templateId: string }>();
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [fetchError, setFetchError] = useState(false); // Track fetch errors
  const filters = {
    "terminalIds": ["All"],
    "tripType": "Arrival",
    "serviceType": "Passenger",
    "pagination": {
      "limit": 50,
      "offset": 0
    },
    "flightType": "International"
  }

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (filters) {
      console.log('Initializing WebSocket...');
      socketRef.current = createSocket({
        terminalId: filters?.terminalIds || 'all',
      });
      socketRef.current.connect();

      socketRef.current.on('connect', () => {
        console.log('Connected to server with socket ID:', socketRef.current?.id, new Date());
      });

      socketRef.current.on('disconnect', () => {
        console.log('Disconnected from server', new Date());
      });

      return () => {
        setTimeout(() => {
          if (socketRef.current) {
            console.log('Disconnecting WebSocket...');
            socketRef.current.disconnect();
            socketRef.current = null;
          }
        }, 500);
      };
    }
  }, [filters]);


  useEffect(() => {
    fetchTemplate();

  }, []);

  const fetchTemplate = async () => {
    try {
      const response = await axiosInstanceTemplate.get(`/templates/getById/${templateId}`);
      const template = response.data["data"];
      if (template) {
        setSelectedTemplate(template);
        const styles = template.body.inLineStyle;

        // Apply styles to the body tag
        const applyBodyStyles = (styleArray: any) => {
          styleArray.forEach((style: any) => {
            document.body.style[style.prop] = style.value;
            console.log(style.prop)
          });
        };
        applyBodyStyles(styles);
      }
      else {
        setFetchError(true);
      }
    } catch (err) {
      console.error("Failed to fetch rules:", err);
    }
  };

  // Function to dynamically calculate rows per page based on viewport height
  const calculateRowsPerPage = () => {
    const viewportHeight = window.innerHeight;
    const footerHeight = 40; // Use desktop or mobile header height
    const headerHeight = window.innerWidth > 768 ? 56 : 93.8; // Use desktop or mobile header height
    const rowHeight = window.innerWidth > 768 ? 42.5 : 38.9; // Use desktop or mobile row height

    const availableHeight = viewportHeight - footerHeight - headerHeight - 43.3; // Adjust for thead height as well
    const newRowsPerPage = Math.floor(availableHeight / rowHeight);
    setRowsPerPage(newRowsPerPage);
  };

  useEffect(() => {
    // Calculate rows per page initially
    calculateRowsPerPage();

    // Recalculate on window resize
    window.addEventListener('resize', calculateRowsPerPage);
    return () => window.removeEventListener('resize', calculateRowsPerPage);
  }, []);


  useEffect(() => {

    const intervalId = setInterval(() => {
      const now = moment();
      setCurrentTime(now);

      const currentDate = now.format('YYYY-MM-DD');
      if (currentDate !== previousDate) {
        console.log("Date has changed!");
        if (previousDate) setIsDateChanges(true);
        setPreviousDate(currentDate); // Update previous date
      }
    }, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      // Reset body background color when the component unmounts
      document.body.style.backgroundColor = '';
    };
  }, [previousDate]);

  // Reset `isDateChanges` after notifying ArrivalComponent
  useEffect(() => {
    if (isDateChanges) {
      console.log("Notifying ArrivalComponent of date change...");
      setIsDateChanges(false); // Reset after notifying
    }
  }, [isDateChanges]); // Trigger when `isDateChanges` is updated

  return (
    <div>
      {fetchError ? (
        <NotFoundTemplate />
      ) : (
        <>
          <div className='row m-0 text-white'>
            <div className='col-md-3'>{selectedTemplate?.tripType && (<h1 className='ml-2'>{selectedTemplate?.tripType}s</h1>)}</div>
            <div className='col-md-6 t-date'>
              <h5>
                {moment(currentTime).format('dddd DD MMMM YYYY')} <span className='ml-3'>{moment(currentTime).format('HH:mm')}</span>
              </h5>
            </div>
            <div className='col-md-3 t-date'>
              <h5 className="text-xl">Updated at {moment(updatedAt).format('HH:mm')}</h5>
            </div>
          </div>
          {selectedTemplate?._id && (
            <SocketProvider socket={socketRef.current}>
              <TemplateComponent
                filters={{ ...filters, serviceType: selectedTemplate.serviceType, flightType: selectedTemplate.flightType }}
                template={selectedTemplate}
                isDateChanges={isDateChanges}
                rowsPerPage={rowsPerPage}
                onUpdate={(date) => setUpdatedAt(date)}
              ></TemplateComponent>
            </SocketProvider>
          )}
        </>
      )}
    </div>
  );
};

export default TemplatePage;
