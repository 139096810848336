import axios from 'axios';
import axiosInstance from './axiosConfig';

interface ApiResponse<T> {
    statusCode: number;
    message: string;
    data?: T | null; 
    success: boolean;
}


export const getData = async <T>(url: string, params = {}): Promise<ApiResponse<T>> => {
    try {
        const response = await axiosInstance.get<ApiResponse<T>>(url,
            {
                headers: { 'Timezone': 'Asia/Kuala_Lumpur' },
                params
            });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const serverError = error.response.data;
            return {
                statusCode: serverError.statusCode || 500,
                message: serverError.message || 'An error occurred',
                success: false,
            };
        } else {
            return {
                statusCode: 500,
                message: 'An unexpected error occurred',
                success: false,
            };
        }
    }
};
