import React, { useEffect, useRef, useState } from 'react';
import TemplateComponent from '../components/TemplateComponent';
import moment from 'moment';
import axiosInstanceTemplate from '../utils/axiosConfigTemplate';
import { Template } from '../interface/template';
import NotFoundTemplate from '../components/NotFoundTemplate';
import axiosInstanceDCMM from '../utils/axiosConfigDCMM';
import { apiRoutes } from '../utils/apiRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetails, Filters } from '../interface/device.details';
import { Socket } from 'socket.io-client';
import { createSocket } from '../socket/create.socket';
import { SocketProvider } from '../context/SocketContext';
const STORAGE_SERIAL_NUMBER_KEY = "STORAGE_SERIAL_NUMBER_KEY";

const ConnectDevicePage: React.FC = () => {
  const [updatedAt, setUpdatedAt] = useState<Date>();
  const [currentTime, setCurrentTime] = useState(moment());
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default value, dynamically updated
  const [previousDate, setPreviousDate] = useState(moment().format('YYYY-MM-DD')); // Track previous date
  const [isDateChanges, setIsDateChanges] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [fetchError, setFetchError] = useState(false); // Track fetch errors
  const [searchError, setSearchError] = useState<string | null>(null); // Error handling for search
  const [deviceSerialNumber, setDeviceSerialNumber] = useState<string>(localStorage.getItem(STORAGE_SERIAL_NUMBER_KEY) || ""); // Serial number input
  const [showData, setShowData] = useState(false);
  const [deviceData, setDeviceData] = useState<DeviceDetails>();
  const [onPageLoad, setOnPageLoad] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<{ lat: number; lon: number } | null>(null);
  const socketRef = useRef<Socket | null>(null);


  useEffect(() => {
    if (deviceData) {
      console.log('Initializing WebSocket...');
      socketRef.current = createSocket({
        deviceId: deviceData?._id,
        terminalId: deviceData?.filters?.terminalIds || 'all',
      });
      socketRef.current.connect();

      socketRef.current.on('connect', () => {
        console.log('Connected to server with socket ID:', socketRef.current?.id, moment(new Date()).format("hh:mm:ss"));
      });

      socketRef.current.on('disconnect', () => {
        console.log('Disconnected from server', moment(new Date()).format("hh:mm:ss"));
      });

      return () => {
        setTimeout(() => {
          if (socketRef.current) {
            console.log('Disconnecting WebSocket...');
            socketRef.current.disconnect();
            socketRef.current = null;
          }
        }, 500);
      };
    }
  }, [deviceData]);

  useEffect(() => {
    // Request user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error fetching location:', error);
          setUserLocation(null);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleSearchDevice = async () => {
    try {
      setSearchError(null); // Clear any previous error
      // setSelectedTemplate(null); // Reset template data

      // Fetch the device by serial number
      const deviceResponse = await axiosInstanceDCMM.get(`${apiRoutes.dcmm.findBySerialNumber}/${deviceSerialNumber}`);
      const deviceResult = deviceResponse.data;

      if (deviceResponse.status === 200 && deviceResult.success) {
        const device = deviceResult.data;
        setDeviceData(device);
      } else {
        setSearchError(deviceResult.message || 'Device not found.');
      }
    } catch (error: any) {
      console.error('Error fetching device or template:', error);
      setSearchError(error.response["data"]["message"]);
    }
  };

  useEffect(() => {
    const storedSerialNumber = localStorage.getItem(STORAGE_SERIAL_NUMBER_KEY);
    if (storedSerialNumber) {
      setDeviceSerialNumber(storedSerialNumber);
      handleSearchDevice();
      setOnPageLoad(true);
    }
  }, []);

  useEffect(() => {
    if (deviceData && onPageLoad) {
      getTemplate();
    }
  }, [deviceData, onPageLoad]);

  const getTemplate = async () => {
    if (deviceData?.templateId) {

      // Update location using the provided API
      await axiosInstanceDCMM.patch(`${apiRoutes.dcmm.updateLocation}/${deviceData._id}`, {
        latitude: userLocation?.lat,
        longitude: userLocation?.lon,
      });

      const templateResponse = await axiosInstanceTemplate.get(`${apiRoutes.templates.getById}/${deviceData?.templateId}`);
      let templateResult = templateResponse.data.data;

      if (templateResult) {
        localStorage.setItem("STORAGE_SERIAL_NUMBER_KEY", deviceSerialNumber);
        templateResult = { ...templateResult, serviceType: deviceData?.filters.serviceType, tripType: deviceData?.filters.tripType }
        const styles = templateResult.body.inLineStyle;

        applyBodyStyles(styles);
        setSelectedTemplate(templateResult);
        setShowData(true);
      } else {
        setSearchError('Template not found for the device.');
      }
    } else {
      setSearchError('No template associated with this device.');
    }
  }


  const applyBodyStyles = (styleArray: any) => {
    styleArray.forEach((style: any) => {
      document.body.style[style.prop] = style.value;
    });
  };

  // Function to dynamically calculate rows per page based on viewport height
  const calculateRowsPerPage = () => {
    const viewportHeight = window.innerHeight;
    const footerHeight = 40; // Use desktop or mobile header height
    const headerHeight = window.innerWidth > 768 ? 56 : 93.8; // Use desktop or mobile header height
    const rowHeight = window.innerWidth > 768 ? 42.5 : 38.9; // Use desktop or mobile row height

    const availableHeight = viewportHeight - footerHeight - headerHeight - 43.3; // Adjust for thead height as well
    const newRowsPerPage = Math.floor(availableHeight / rowHeight);
    setRowsPerPage(newRowsPerPage);
  };

  useEffect(() => {
    // Calculate rows per page initially
    calculateRowsPerPage();

    // Recalculate on window resize
    window.addEventListener('resize', calculateRowsPerPage);
    return () => window.removeEventListener('resize', calculateRowsPerPage);
  }, []);


  useEffect(() => {

    const intervalId = setInterval(() => {
      const now = moment();
      setCurrentTime(now);

      const currentDate = now.format('YYYY-MM-DD');
      if (currentDate !== previousDate) {
        console.log("Date has changed!");
        if (previousDate) setIsDateChanges(true);
        setPreviousDate(currentDate); // Update previous date
      }
    }, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      // Reset body background color when the component unmounts
      document.body.style.backgroundColor = '';
    };
  }, [previousDate]);

  // Reset `isDateChanges` after notifying ArrivalComponent
  useEffect(() => {
    if (isDateChanges) {
      console.log("Notifying ArrivalComponent of date change...");
      setIsDateChanges(false); // Reset after notifying
    }
  }, [isDateChanges]); // Trigger when `isDateChanges` is updated

  return (
    <div>
      <div>

        {!showData && (<div className="container mt-4">
          <h3>Connect Device</h3>
          <div className="form-group d-flex">
            <input
              type="text"
              id="deviceSerialNumber"
              className="form-control me-2"
              placeholder="Enter Serial Number"
              value={deviceSerialNumber}
              onChange={(e) => setDeviceSerialNumber(e.target.value)}
            />
            <button className="btn btn-primary" onClick={() => {
              setOnPageLoad(false);
              handleSearchDevice();
            }}>
              Search
            </button>
          </div>

          {searchError && <div className="alert alert-danger mt-3">{searchError}</div>}
        </div>)}

        {!searchError && !showData && deviceData && (
          <div className="container mt-4">
            <div className="card shadow-lg border-0">
              <div className="card-header bg-primary text-white">
                <h5 className="card-title d-flex justify-content-between align-items-center mb-0">
                  Filters
                  <button onClick={getTemplate} className="btn btn-light btn-sm">
                    Apply Filters
                  </button>
                </h5>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <h5 className="mb-1"><strong>Terminal ID:</strong></h5>
                    <p>{deviceData.filters?.terminalIds || 'N/A'}</p>
                  </div>
                  <div className="col-md-4">
                    <h5 className="mb-1"><strong>Trip Type:</strong></h5>
                    <p>{deviceData.filters?.tripType || 'N/A'}</p>
                  </div>
                  <div className="col-md-4">
                    <h5 className="mb-1"><strong>Pagination</strong></h5>
                    <p><span>Page number:</span> {((deviceData?.filters?.pagination?.offset / deviceData?.filters?.pagination?.limit) + 1)} ({deviceData.filters?.pagination?.limit || 'N/A'} records)</p>
                  </div>
                  <div className="col-md-4">
                    <h5 className="mb-1"><strong>Service Type:</strong></h5>
                    <p>{deviceData.filters?.serviceType || 'N/A'}</p>
                  </div>
                  <div className="col-md-4">
                    <h5 className="mb-1"><strong>Flight Type:</strong></h5>
                    <p>{deviceData.filters?.flightType || 'N/A'}</p>
                  </div>
                  {deviceData?.filters?.airlines?.length && (<div className="col-md-4">
                    <h5 className="mb-1"><strong>Airline:</strong></h5>
                    <p>{deviceData.filters?.airlines.map(x => x.name).join(",") || 'N/A'}</p>
                  </div>)}

                  {deviceData?.filters?.standCodes?.length && (<div className="col-md-4">
                    <h5 className="mb-1"><strong>Gate:</strong></h5>
                    <p>{deviceData.filters?.standCodes?.join(",") || 'N/A'}</p>
                  </div>)}

                  {deviceData?.filters?.destinations?.length && (<div className="col-md-4">
                    <h5 className="mb-1"><strong>Destinations:</strong></h5>
                    <p>{deviceData.filters.destinations.join(",") || 'N/A'}</p>
                  </div>)}
                </div>
              </div>
            </div>
          </div>
        )}


        {selectedTemplate && showData && (
          <div>
            <div className='row m-0 text-white'>
              <div onClick={() => {
                applyBodyStyles([{ "prop": "background-color", "value": "white" }]);
                setShowData(false);
              }}
                className='col-md-3'>{selectedTemplate?.tripType && (<h1 className='ml-2'>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  {selectedTemplate?.tripType}s</h1>)}</div>
              <div className='col-md-6 t-date'>
                <h5>
                  {moment(currentTime).format('dddd DD MMMM YYYY')} <span className='ml-3'>{moment(currentTime).format('HH:mm')}</span>
                </h5>
              </div>
              <div className='col-md-3 t-date'>
                <h5 className="text-xl">Updated at {moment(updatedAt).format('HH:mm')}</h5>
              </div>
            </div>
            {selectedTemplate?._id && (
              <SocketProvider socket={socketRef.current}>
                <TemplateComponent
                  deviceId={deviceData?._id}
                  filters={deviceData?.filters!}
                  template={selectedTemplate}
                  isDateChanges={isDateChanges}
                  rowsPerPage={rowsPerPage}
                  onUpdate={(date) => setUpdatedAt(date)}
                ></TemplateComponent>
              </SocketProvider>
            )}
          </div>
        )}

        {fetchError && <NotFoundTemplate />}
      </div>
    </div>
  );
};

export default ConnectDevicePage;
