import axios from 'axios';

const axiosInstanceDCMM = axios.create({
  baseURL: process.env.REACT_APP_DCMM_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


export default axiosInstanceDCMM;
