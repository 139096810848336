import React, { useEffect, useRef, useState } from 'react';
import { FlightDetails, FlightStatus, Rules, Station } from '../interface/flight.details';
import moment from 'moment';
import { cargoServiceCodes, RemarkToCodeMapping, IATACodeToCity, ServiceType, TripType } from '@industronics/fids-utils';
import { handleGateChange, toCamelCase } from '../utils/common.utils';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Template, TemplateColumn } from '../interface/template';
import { getData } from '../utils/api';
import { apiRoutes } from '../utils/apiRoutes';
import { Filters } from '../interface/device.details';
import { useSocket } from '../context/SocketContext';



interface TemplateComponentProps {
  filters: Filters;
  template: Template,
  onUpdate: (data: Date) => void;
  rowsPerPage: number;
  isDateChanges?: boolean,
  deviceId?: string;
}

const TemplateComponent: React.FC<TemplateComponentProps> = ({ deviceId, filters, template, onUpdate, rowsPerPage, isDateChanges }) => {

  const [flights, setFlights] = useState<FlightDetails[]>([]);
  const [rules, setRules] = useState<Rules[]>([]);
  const displayOffset = process.env.REACT_APP_DISPLAY_OFFSET_MINS || 20;
  const timezone = process.env.REACT_APP_TIMEZONE || 8;
  const [animatedFlightIds, setAnimatedFlightIds] = useState<{ [key: string]: boolean }>({}); // Track which flights are animating
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [isFading, setIsFading] = useState(false); // Track fading effect
  const { socket } = useSocket();

  // Automatic page flipping

  const totalPages = Math.ceil(flights.length / rowsPerPage);
  const currentFlights = flights.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const longestFlightNumberLength = currentFlights?.reduce(
    (max, current) => (current.flightNumber.length > max ? current.flightNumber.length : max),
    0
  );


  useEffect(() => {
    // Apply fade-out and fade-in effect on page flip
    if (totalPages === 1) {
      setCurrentPage(1);
      return;
    };
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
        setIsFading(false); // Reset to fade-in
      }, 500); // Duration should match CSS transition
    }, currentPage === 1 ? 10 * 1000 : 5 * 1000);

    return () => clearInterval(interval);
  }, [currentPage, totalPages]);

  // Navigate between pages
  const goToPreviousPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : Math.ceil(flights.length / rowsPerPage)));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };

  const goToNextPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage < Math.ceil(flights.length / rowsPerPage) ? prevPage + 1 : 1));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };


  useEffect(() => {
    if (socket && filters.tripType === TripType.Departure) {
      socket.on('rule_created', (message: any) => {
        console.log("rule_created", message);
        setRules([...rules, message]);
      });
      socket.on('rule_deleted', (message: any) => {
        console.log("rule_deleted", message);
        setRules(rules.filter(x => x._id !== message._id));
      });

      return () => {
        socket.off("rule_created");
        socket.off("rule_deleted");
      };
    }
  }, []);

  useEffect(() => {
    if (!socket) return;
    if (filters.tripType === TripType.Departure) {
      socket.on('flight_update_departure', (flightData: FlightDetails) => {
        console.log("flight_update_departure", flightData);

        setFlights((prevFlights) => {
          const flightIndex = prevFlights.findIndex(flight => flight._id === flightData._id);
          // Update existing flight data or add new flight
          let updatedFlights: FlightDetails[] = [];
          if (flightIndex !== -1) {
            updatedFlights = [...prevFlights];

            if (flightData?.remarkCode && updatedFlights[flightIndex]?.remarkCode?.key !== flightData?.remarkCode?.key) {
              // Trigger animation for flight remark change
              setAnimatedFlightIds((prev) => ({
                ...prev,
                [flightData._id]: true,
              }));
              // Remove animation after some time
              setTimeout(() => {
                setAnimatedFlightIds((prev) => ({
                  ...prev,
                  [flightData._id]: false,
                }));
              }, 1000); // Duration for animation
            }

            updatedFlights[flightIndex] = { ...flightData, standCodeChanged: flightData.standCode != updatedFlights[flightIndex].standCode };

          }
          else {
            if (prevFlights.length < (filters.pagination.limit || 160)
              && flightData.scheduledTravelTime && prevFlights.length &&
              new Date(flightData.scheduledTravelTime) > new Date(prevFlights[prevFlights.length - 1].scheduledTravelTime)) {
              updatedFlights = [...prevFlights, flightData];
            }
            else if (prevFlights.length === (filters.pagination.limit || 160)
              && flightData.scheduledTravelTime && prevFlights.length
              && new Date(flightData.scheduledTravelTime) >= new Date(prevFlights[0].scheduledTravelTime) && new Date(flightData.scheduledTravelTime) < new Date(prevFlights[prevFlights.length - 1].scheduledTravelTime)
            ) {
              updatedFlights = prevFlights;
              updatedFlights.pop();
              updatedFlights.unshift(flightData);
            }
            else updatedFlights = [...prevFlights];
          }

          // Current time and other calculations
          const currentTime = Date.now(); // Get the current time in milliseconds
          const displayOffsetInMillis = displayOffset * 60 * 1000; // Offset minutes in milliseconds

          // Filter the flights based on isDisplay and ETA condition
          updatedFlights = updatedFlights
            .filter(item => {
              return item.isDisplay && (!item.actualArrivalDepartureTime || (item.actualArrivalDepartureTime && (new Date(item.actualArrivalDepartureTime).getTime() + displayOffsetInMillis > currentTime)));
            })
          if (flightIndex === -1) {
            return updatedFlights.sort((a, b) => {
              return new Date(a.scheduledTravelTime).getTime() - new Date(b.scheduledTravelTime).getTime();
            });
          }
          return updatedFlights;
        });

        onUpdate && onUpdate(new Date());
      });

      // Cleanup the socket connection when the component unmounts
      return () => {
        socket.off('flight_update_departure');
      };
    }
    else if (filters.tripType === TripType.Arrival) {
      socket.on('flight_update_arrival', (flightData: FlightDetails) => {
        setFlights((prevFlights) => {
          const flightIndex = prevFlights.findIndex(flight => flight._id === flightData._id);
          let updatedFlights;
          if (flightIndex !== -1) {
            updatedFlights = [...prevFlights];

            if (flightData?.remarkCode && updatedFlights[flightIndex]?.remarkCode?.key !== flightData?.remarkCode?.key) {
              // Trigger animation for flight remark change
              setAnimatedFlightIds((prev) => ({
                ...prev,
                [flightData._id]: true,
              }));
              // Remove animation after some time
              setTimeout(() => {
                setAnimatedFlightIds((prev) => ({
                  ...prev,
                  [flightData._id]: false,
                }));
              }, 1000); // Duration for animation
            }

            updatedFlights[flightIndex] = flightData;
          } else {
            if (prevFlights.length < (filters.pagination.limit || 160)
              && flightData.scheduledTravelTime && prevFlights.length
              && new Date(flightData.scheduledTravelTime) > new Date(prevFlights[prevFlights.length - 1].scheduledTravelTime)) {
              updatedFlights = [...prevFlights, flightData];
            }
            else if (prevFlights.length === (filters.pagination.limit || 160)
              && flightData.scheduledTravelTime && prevFlights.length
              && new Date(flightData.scheduledTravelTime) >= new Date(prevFlights[0].scheduledTravelTime) && new Date(flightData.scheduledTravelTime) < new Date(prevFlights[prevFlights.length - 1].scheduledTravelTime)
            ) {
              updatedFlights = prevFlights;
              updatedFlights.pop();
              updatedFlights.unshift(flightData);
            }
            else updatedFlights = [...prevFlights];
          }

          const currentTime = Date.now();
          const displayOffsetInMillis = displayOffset * 60 * 1000;

          updatedFlights = updatedFlights.filter(x => {
            if (!x.isDisplay) return false;
            if (x.remarkCode && x.remarkCode.key === RemarkToCodeMapping.CANCELLED && currentTime > new Date(x.actualArrivalDepartureTime).getTime() + displayOffsetInMillis) return false;
            else if (x.lastBagReceivedAt && currentTime > new Date(x.lastBagReceivedAt).getTime() + displayOffsetInMillis) return false;
            else if (
              (x.remarkCode && x.remarkCode.key === RemarkToCodeMapping.CLOSED_CCL) || (x.actualArrivalDepartureTime &&
                cargoServiceCodes.includes(x.serviceTypeCode) &&
                currentTime > new Date(x.actualArrivalDepartureTime).getTime() + displayOffsetInMillis)
            ) {
              return false;
            }
            return true;
          });
          if (flightIndex === -1) {
            return updatedFlights.sort((a, b) => {
              return new Date(a.scheduledTravelTime).getTime() - new Date(b.scheduledTravelTime).getTime();
            });;
          }
          return updatedFlights;
        });

        let date = new Date();
        onUpdate && onUpdate(date);
      });

      return () => {
        socket.off('flight_update_arrival');
      };
    }
  }, []);

  useEffect(() => {
    if (filters.serviceType === ServiceType.Passenger && filters.tripType === TripType.Departure) {
      fetchRules();
    }
    if (isDateChanges) fetchFlights();
  }, [isDateChanges]);

  const fetchRules = async () => {
    try {
      const response = await getData<any[]>(apiRoutes.rules.getAllRules);
      if (response.success && response.data) setRules(response.data);
    } catch (err) {
      console.error("Failed to fetch rules:", err);
    }
  };

  const checkRule = (flight: FlightDetails) => {
    const now = moment();
    const flightETA = moment(new Date(flight?.ETA));
    if (
      flight?.remarkCode?.key === RemarkToCodeMapping.GATE_OPEN
      || flight?.remarkCode?.key === RemarkToCodeMapping.GATE_CLOSED
      || flight?.remarkCode?.key === RemarkToCodeMapping.CANCELLED
      || flight?.remarkCode?.key === RemarkToCodeMapping.FINAL_CALL
      || flight?.remarkCode?.key === RemarkToCodeMapping.DEPARTED
      || now.isAfter(flightETA, 'minute')
    ) return null;
    return true;
  }

  const getRuleRemark = (flight: FlightDetails): FlightStatus | undefined | null => {
    const now = moment();
    const flightETA = moment(new Date(flight?.ETA));

    const gateShownRule = rules.find(rule => rule?.remarkCode?.key === RemarkToCodeMapping.GATE_SHOWN); // Gate Shown
    const goToGateRule = rules.find(rule => rule?.remarkCode?.key === RemarkToCodeMapping.GO_TO_GATE); // Go to Gate

    if (!gateShownRule || !goToGateRule) return null;

    const gateShownTime = flightETA.clone().subtract(gateShownRule.hours * 60, 'minutes');
    const goToGateTime = flightETA.clone().subtract(goToGateRule.hours * 60, 'minutes');

    if (now.isBetween(gateShownTime, goToGateTime)) {
      return { key: RemarkToCodeMapping.GATE_SHOWN, description: `${gateShownRule?.remarkCode?.description} ${gateShownRule?.remarkCode?.key === RemarkToCodeMapping.GATE_SHOWN ? " at " + gateShownTime.format('HH:mm') : ""}` };
    } else if (now.isSameOrAfter(goToGateTime)) {
      if (flight.standCodeChanged) return { key: RemarkToCodeMapping.GATE_CHANGE, description: "Gate Change" };
      return { key: RemarkToCodeMapping.GO_TO_GATE, description: `${goToGateRule?.remarkCode?.description} ${goToGateRule?.remarkCode?.key === RemarkToCodeMapping.GATE_SHOWN ? " at " + goToGateTime.format('HH:mm') : ""}` };
    }

    return null;
  };


  useEffect(() => {
    if (!socket) return;
    socket.on("server_started", () => {
      console.log("Server started with socket ID:", socket.id);
      fetchFlights();
    });

    return () => {
      socket.off("server_started");
    };
  }, []);

  useEffect(() => {
    fetchFlights();
  }, []);

  useEffect(() => {
    const handleOnline = () => fetchFlights();
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const fetchFlights = async () => {
    try {
      const params = {
        airlineIds: filters?.airlines?.join(","),
        destinations: filters?.destinations?.join(","),
        standCodes: filters?.standCodes?.join(","),
        offset: filters.pagination.offset,
        displayOffset,
        tripType: filters.tripType, limit: filters.pagination.limit,
        serviceType: filters.serviceType,
        terminalIds: filters?.terminalIds?.find(x=>x.toLowerCase()!== "all") ? filters?.terminalIds?.join(",") : null
      }

      const response = await getData<any[]>(apiRoutes.flightTrips.getFlightTrips, params);
      if (response.success && response.data) setFlights(response.data);
      if (filters.tripType === TripType.Departure) localStorage.setItem("fetchFlightsDeparture", new Date().toString());
      else if (filters.tripType === TripType.Arrival) localStorage.setItem("fetchFlightsArrival", new Date().toString());

      const currentDate = new Date();
      onUpdate(currentDate); // Update the last updated time

    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <table style={{ width: "100%" }}
        className={`w-full text-center ${isFading ? 'shimmer' : ''}`}
      >
        <thead className="bg-gray-700">
          <tr style={{ border: "1px solid" }}>

            {template?.columns?.map((col: TemplateColumn) => (
              <th
                key={col.field}
                className={col.header?.cssClass || ""}
                style={(col.header?.inLineStyle || []).reduce((styleObj: any, style) => {
                  const camelCaseProp = toCamelCase(style.prop);
                  styleObj[camelCaseProp] = style.value;
                  return styleObj;
                }, {})}
              >
                {filters.tripType === TripType.Departure && col.header?.label === "From" ? "To" : filters.tripType === TripType.Arrival && col.header?.label === "To" ? "From" : col.header?.label || ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentFlights.map((flight: any, index) => (
            <tr style={{
              backgroundColor: index % 2 == 0 ? template.alternateRow.background : "initial",
              color: index % 2 == 0 ? template.alternateRow.color : "initial"
            }} key={index}
              className="odd:bg-gray-600 even:bg-gray-700">

              {template?.columns?.map((col: any) => {
                let cellContent;

                switch (col.field) {
                  case "ETA":
                  case "counterOpeningTime":
                  case "counterClosingTime":
                  case "loungeOpeningTime":
                  case "loungeClosingTime":
                  case "loungeBoardingTime":
                  case "finalLoungeTime":
                  case "lastBagReceivedAt":
                  case "scheduledTravelTime":
                  case "officialFlightDate":
                    cellContent = (
                      <span
                        style={(col.body?.inLineStyle || []).reduce((styleObj: any, style: any) => {
                          const camelCaseProp = toCamelCase(style.prop);
                          if (camelCaseProp !== "backgroundColor") styleObj[camelCaseProp] = style.value;
                          if (camelCaseProp === "color") styleObj[camelCaseProp] = index % 2 == 0 ? template?.alternateRow?.color : style.value;
                          return styleObj;
                        }, {})}
                      >
                        {moment(flight[col.field]).utcOffset(timezone).format(col.body?.time?.format || "HH:mm")}
                        <span>
                          {
                            moment(flight[col.field]).utcOffset(timezone).isSame(moment(), 'day')
                              ? ''
                              : (
                                <span>
                                  <span>, </span>
                                  <small
                                    style={{
                                      ...(col.body?.date?.inLineStyle || []).reduce((styleObj: any, style: any) => {
                                        const camelCaseProp = toCamelCase(style.prop);
                                        styleObj[camelCaseProp] = style.value;
                                        return styleObj;
                                      }, {}),
                                    }}
                                  >{moment(flight[col.field]).utcOffset(timezone).format(col.body?.date?.format || "DD MMM")}</small>
                                </span>
                              )}
                        </span>
                      </span>
                    );
                    break;
                  case "flightNumber":
                    if (!col?.body?.showFlightsInline) {
                      cellContent = (<div>
                        {col?.body?.showLogo && <img
                          className='flight-logo'
                          src='/ak.png' />}
                        <label style={{ width: template.pageFontSize ? (((parseInt(template.pageFontSize, 10) - 10) * 4 + (longestFlightNumberLength <= 7 ? 45 : 55)) + "px") : "10px" }}>
                          {flight.flightNumber}
                        </label>
                        {flight?.codeShareFlight?.length > 0 && <span> | </span>}
                        {flight?.codeShareFlight?.length > 0 && (
                          flight?.codeShareFlight?.length > 1 ? (
                            <div
                              className={"text-flip"}
                              style={{
                                height: template.pageFontSize
                                  ? `${parseInt(template.pageFontSize, 10) + 4}px`
                                  : "15px",
                                '--items': flight.codeShareFlight.length + (flight?.codeShareFlight?.length > 1 ? 1 : 0),
                                '--item-duration': '1.2s',
                                '--animation-duration': `${(flight.codeShareFlight.length + 1) * 1.2}s`,
                              } as React.CSSProperties}
                            >
                              <div className="text-container">
                                {flight.codeShareFlight.map((item: string, index: number) => (
                                  <div key={item}>
                                    {col?.body?.showLogo && (
                                      <img
                                        className='flight-logo'
                                        src="/ak.png"
                                      />
                                    )}
                                    <label key={index}>{item}</label>
                                  </div>
                                ))}
                                <div>
                                  {col?.body?.showLogo && (
                                    <img
                                      className='flight-logo'
                                      src="/ak.png"
                                    />
                                  )}
                                  <label>{flight.codeShareFlight[0]}</label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <span>
                              {col?.body?.showLogo && (
                                <img
                                  className='flight-logo'
                                  src="/ak.png"
                                />
                              )}
                              <span>{flight.codeShareFlight[0]}</span>
                            </span>
                          )
                        )}

                      </div>);
                    }
                    else {
                      const updatedCodeShareFlight = [
                        flight.flightNumber,
                        ...(flight?.codeShareFlight || []),
                      ];
                      cellContent = (
                        <div>
                          {(
                            <div
                              className="text-flip"
                              style={{
                                '--items': updatedCodeShareFlight.length + (updatedCodeShareFlight.length > 1 ? 1 : 0), // Add an extra slot for flightNumber
                                '--item-duration': '1.2s', // Duration for each item
                                '--animation-duration': `${(updatedCodeShareFlight.length + 1) * 1.2}s`, // Total animation duration
                              } as React.CSSProperties}
                            >
                              <div className="text-container">
                                {updatedCodeShareFlight.length > 1 && updatedCodeShareFlight.map((item: string, index: number) => (
                                  <div key={item}>
                                    {col?.body?.showLogo && (
                                      <img
                                        className='flight-logo'
                                        src="/ak.png"
                                        alt="Airline Logo"
                                      />
                                    )}
                                    <label className={item === flight.flightNumber ? 'main-flight' : ""}>{item}</label>
                                  </div>
                                ))}

                                <div>
                                  {col?.body?.showLogo && (
                                    <img
                                      className='flight-logo'
                                      src="/ak.png"
                                      alt="Airline Logo"
                                    />
                                  )}
                                  <label className={updatedCodeShareFlight.length > 1 && updatedCodeShareFlight[0] === flight.flightNumber ? 'main-flight' : ""}>{updatedCodeShareFlight[0]}</label>
                                </div>


                              </div>
                            </div>
                          )}
                        </div>
                      );

                    }
                    break;
                  case "stations":
                    let updatedStations: Station[] = [];
                    if (filters.tripType === TripType.Departure) updatedStations = flight.stations.filter((_: any, index: number) => index !== 0);
                    else updatedStations = flight.stations.filter((_: any, index: number) => index !== flight.stations.length - 1);

                    cellContent = (
                      <div>
                        {(
                          <div
                            className={updatedStations.length > 1 ? "text-flip" : ""}
                            style={{
                              width: "100%",
                              '--items': updatedStations.length + (updatedStations.length > 1 ? 1 : 0), // Add an extra slot for flightNumber
                              '--item-duration': '1.2s', // Duration for each item
                              '--animation-duration': `${(updatedStations.length + 1) * 1.2}s`, // Total animation duration
                            } as React.CSSProperties}
                          >
                            <div className="text-container">
                              {updatedStations.length > 1 && updatedStations.map((item: Station, index: number) => (
                                <div key={item.stationNumber}>
                                  <label className={updatedStations.length > 1 && (filters.tripType === TripType.Departure ? index + 1 === updatedStations.length : index === 0) ? 'main-flight' : ""}>{IATACodeToCity[item.airport]}</label>
                                </div>
                              ))}

                              <div>
                                <label >{IATACodeToCity[updatedStations[0]?.airport]}</label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>)

                    break;
                  case "standCode":
                    cellContent = (
                      <span className="p-1"> {template?.flightType == ServiceType.Passenger ? (getRuleRemark(flight)?.key === RemarkToCodeMapping.GATE_SHOWN ? "" : handleGateChange(flight.standCode)) : flight.standCode}</span>
                    );
                    break;

                  case "checkInType":
                    cellContent = (
                      <span className="p-1"> {flight?.checkInType?.description}</span>
                    );
                    break;

                  case "loungeCode":
                    cellContent = (
                      <span className="p-1"> {template?.flightType == ServiceType.Passenger ? handleGateChange(flight.loungeCode) : flight.loungeCode}</span>
                    );
                    break;
                  case "remarkCode":
                    const specificStyles =
                      col.body?.specificStyles?.[flight?.remarkCode?.key] || {};

                    cellContent = (
                      <span
                        className={"p-1"}
                        style={(specificStyles?.inLineStyle || []).reduce(
                          (styleObj: any, style: any) => {
                            styleObj[style.prop] = style.value;
                            return styleObj;
                          },
                          {}
                        )}
                      >
                        <span className={`remark ${animatedFlightIds[flight._id] ? 'animate-swap' : ''}`}>
                          {filters.serviceType === ServiceType.Passenger && flight.ETA && checkRule(flight) && flight?.remarkCode?.key !== RemarkToCodeMapping.DELAYED ? "" : flight?.remarkCode?.description}
                          {filters.serviceType === ServiceType.Passenger && flight.ETA && checkRule(flight) && (
                            <span style={{ fontSize: !checkRule(flight) ? "small" : "23px" }} className="text-arrival"> {getRuleRemark(flight)?.description}</span>
                          )}
                        </span>
                      </span>
                    )
                    break;
                  default:
                    cellContent = flight[col.field] || "";
                }

                return (
                  <td
                    key={col.field}
                    className={col.body?.cssClass || ""}
                    style={{
                      fontSize: template.pageFontSize || "23px",
                      ...col.body?.inLineStyle?.reduce((styleObj: any, style: any) => {
                        const camelCaseProp = toCamelCase(style.prop);
                        if (index % 2 == 0 && camelCaseProp === "color") {
                          styleObj[camelCaseProp] = template?.alternateRow?.color || style.value;
                        }
                        else if (index % 2 == 0 && camelCaseProp === "backgroundColor") {
                          styleObj[camelCaseProp] = template?.alternateRow?.background || style.value;
                        }
                        else styleObj[camelCaseProp] = style.value;
                        return styleObj;
                      }, {}),
                    }}
                  >
                    {cellContent}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center text-xl font-semibold my-2 fix-bottom">
        <button disabled={currentPage === 1} onClick={goToPreviousPage}
          className="btn btn-sm rounded  mr-1"
          style={{
            backgroundColor: template?.alternateRow?.background,
            color: template?.alternateRow?.color
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <span
          style={{
            color: template?.alternateRow?.color
          }}
        >Page {currentPage} of {totalPages}</span>

        <button disabled={currentPage === totalPages} onClick={goToNextPage}
          className="btn btn-sm rounded ml-1"
          style={{
            backgroundColor: template?.alternateRow?.background,
            color: template?.alternateRow?.color
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

    </div>
  );
};

export default TemplateComponent;
