import { FlightDetails } from "../interface/flight.details";
import { RemarkToCodeMapping } from "@industronics/fids-utils";

export const getDisplayColor = (remarkCodeKey: string) => {
    let result = "";
    switch (remarkCodeKey) {
        case RemarkToCodeMapping.DELAYED:
        case RemarkToCodeMapping.CANCELLED:
        case RemarkToCodeMapping.GATE_CLOSED:
            result = "text-red";
            break;
        case RemarkToCodeMapping.FINAL_CALL:
        case RemarkToCodeMapping.LAST_BAG:
            result = "text-warning";
            break;

        case RemarkToCodeMapping.DEPARTED:
        case RemarkToCodeMapping.LANDED:
            result = "text-done";
            break;
        case RemarkToCodeMapping.GATE_OPEN:
        case RemarkToCodeMapping.OPEN:
        case RemarkToCodeMapping.OPEN_COP:
            result = "text-pre";
            break;
        default:
            result = "text-defualt";
            break;
    };
    return result;
};

export const handleGateChange = (flight: FlightDetails) => {
    if (!flight.loungeCode) return;
    const firstChar = flight.loungeCode.match(/[A-Za-z]/)?.[0] || "";
    const numericPart = flight.loungeCode.match(/\d+/)?.[0].replace(/^0+/, "") || "";
    return firstChar + numericPart;
};

export const toCamelCase = (prop: string): string => {
    if (prop) return prop.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    return "";
};
