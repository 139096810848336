import React from 'react';

const NotFoundTemplate: React.FC = () => {
  return (
    <div className="not-found-container text-center">
      <h1 className="text-danger">Template Not Found</h1>
      <p className="text-muted">
        The template you are looking for does not exist or has been removed.
      </p>
    </div>
  );
};

export default NotFoundTemplate;
