import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ArrivalsPage from './pages/ArrivalsPage';
import DeparturesPage from './pages/DeparturesPage';
import TemplatePage from './pages/TemplatePage';
import "./components/trip.css";
import ConnectDevicePage from './pages/ConnectDevicePage';

const App: React.FC = () => {
  const [locationPermission, setLocationPermission] = useState<string>('default'); // Tracks location permission state
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    // Request location permission on component mount
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User granted permission
          setLocationPermission('granted');
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          // User denied permission or an error occurred
          if (error.code === error.PERMISSION_DENIED) {
            setLocationPermission('denied');
          } else {
            setLocationPermission('error');
          }
        }
      );
    } else {
      setLocationPermission('unsupported'); // Geolocation is not supported
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/arrivals" element={<ArrivalsPage />} />
          <Route path="/arrivals/passenger" element={<ArrivalsPage />} />
          <Route path="/departures" element={<DeparturesPage />} />
          <Route path="/departures/passenger" element={<DeparturesPage />} />
          <Route path="/template/:templateId" element={<TemplatePage />} />
          <Route path="/connect-device" element={<ConnectDevicePage />} />
          <Route
            path="/"
            element={
              <div className="p-5">
                {/* Display Location Permission Status */}
                <div className="mb-4">
                  {locationPermission === 'default' && <p>Requesting location permission...</p>}
                  {locationPermission === 'granted' && userLocation && (
                    <p>
                      Location permission granted. Latitude: {userLocation.lat}, Longitude: {userLocation.lng}.
                    </p>
                  )}
                  {locationPermission === 'denied' && (
                    <p className="text-danger">Location permission denied. Some features may not work properly.</p>
                  )}
                  {locationPermission === 'unsupported' && (
                    <p className="text-warning">Geolocation is not supported by your browser.</p>
                  )}
                  {locationPermission === 'error' && (
                    <p className="text-danger">An error occurred while requesting location access.</p>
                  )}
                </div>
                {/* Navigation Buttons */}
                <button className="btn btn-primary">
                  <Link className="text-white custom-link" to="arrivals">
                    Arrivals
                  </Link>
                </button>
                <button className="btn btn-primary ml-3">
                  <Link className="text-white custom-link" to="arrivals/passenger">
                    Arrivals(passenger)
                  </Link>
                </button>
                <button className="btn btn-primary ml-3 !important">
                  <Link className="text-white custom-link" to="departures">
                    Departures
                  </Link>
                </button>
                <button className="btn btn-info ml-3 !important">
                  <Link className="text-white custom-link" to="departures/passenger">
                    Departures(Passenger)
                  </Link>
                </button>
                <button className="btn btn-info ml-3 !important">
                  <Link className="text-white custom-link" to="connect-device">
                    Connect Device
                  </Link>
                </button>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
